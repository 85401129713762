<template>
  <footer class="bg-footer" id="footer">
    <div class="container base-container-x pt-2 px-4 border-bottom">
      <div class="row">
        <div class="col-md-12 col-lg-4 col-sm-12 px-2 pt-4 mb-3 float-left">
          <div class="row">
            <div
              class="
                col-md-2 col-lg-4
                pb-3
                pr-md-0 pr-lg-0
                pl-lg-0
                pr-xl-0
                text-md-center
              "
            >
              <a href="/" class="brand"
                ><img
                  class=""
                  alt="WYA Yoga"
                  height="130"
                  width="130"
                  src="../assets/images/logo/logo.png"
              /></a>
            </div>
            <div class="col-md-10 col-lg-8 pl-md-0 pl-lg-0 pl-xl-0">
              <h5
                class="
                  mb-md-4 mb-lg-4
                  font-weight-bold
                  text-uppercase text-primary-dark
                "
              >
                WYA ORGANIZATION
              </h5>
              <span class="article-content text-gray-light"
                >{{ $t("Footer_wya_section_1") }} <br /><br />
                {{ $t("Footer_wya_section_2") }}</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-sm-12 px-2 pt-4 float-left mb-3">
          <h5 class="mb-md-4 mb-lg-4 font-weight-bold text-primary-dark">
            {{ $t("About us") }}
          </h5>
          <span class="article-content text-gray-light">{{
            $t("Footer_contact")
          }}</span>
          <br /><br />
          <div style="font-size: 15px">
            <router-link
              :to="{ name: 'home' }"
              exact
              class="font-weight-bold text-primary-dark mr-2"
              >{{ $t("Home") }}</router-link
            ><span>|</span>
            <router-link
              :to="{ name: 'searchDirectory' }"
              exact
              class="font-weight-bold text-primary-dark ml-2 mr-2"
              >{{ $t("Directory") }}</router-link
            ><span>|</span>
            <router-link
              :to="{ name: 'about' }"
              exact
              class="font-weight-bold text-primary-dark ml-2 mr-2"
              >{{ $t("About us") }}</router-link
            ><span>|</span>
            <!-- <router-link :to="{ name: 'memberBenefit' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2">{{ $t('Member Benefits') }}</router-link><span>|</span> -->
            <router-link
              :to="{ name: 'applyType' }"
              exact
              class="font-weight-bold text-primary-dark ml-2 mr-2"
              >{{ $t("Application Form") }}</router-link
            ><span>|</span>
            <!-- <router-link :to="{ name: 'feeDetails' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2">{{ $t('Fee Details') }}</router-link><span>|</span> -->
            <!-- <router-link :to="{ name: 'termsAndConditions' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2">{{ $t('Terms and Conditions') }}</router-link><span>|</span> -->
            <!-- <router-link :to="{ name: 'privacyPolicy' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2">{{ $t('Privacy Policy') }}</router-link><span>|</span> -->
            <!-- <router-link :to="{ name: 'ourHistory' }" exact class="font-weight-bold text-primary-dark ml-2 mr-2">{{ $t('Our History') }}</router-link><span>|</span> -->
            <!-- <router-link :to="{ name: 'ourTeam' }"  exact class="font-weight-bold text-primary-dark ml-2 mr-2">{{ $t('Our Board') }}</router-link> -->
            <router-link
              :to="{ name: 'legalAgreement' }"
              exact
              class="font-weight-bold text-primary-dark ml-2 mr-2"
              >{{ $t("Legal Agreement") }}</router-link
            ><span>|</span>
            <router-link
              :to="{ name: 'contact' }"
              exact
              class="font-weight-bold text-primary-dark ml-2 mr-2"
              >{{ $t("Contact") }}</router-link
            ><span>|</span>
            <router-link
              :to="{ name: 'feedback' }"
              exact
              class="font-weight-bold text-primary-dark ml-2 mr-2"
              >{{ $t("Feedback") }}</router-link
            >
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-sm-12 px-2 mb-3 pt-4 float-left">
          <h5 class="mb-md-4 mb-lg-4 font-weight-bold text-primary-dark">
            {{ $t("Address") }}
          </h5>
          <p class="text-primary-dark font-weight-bold">
            Shanti Marg, Haripur Kalan, Haridwar, Raiwala, Dehradun, Uttarakhand 249205, India.
          </p>
          <span class="article-content text-gray-light"
            >{{ $t("Phone") }}: +91 9258-780-768</span
          ><br />
          <span class="article-content text-gray-light"
            >{{ $t("Email") }}: contact@wyayoga.org</span
          ><br /><br />
          <p class="article-content text-gray-light">
            {{ $t("Office Hours: MON-FRI, 9:00-17:00") }}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-12 bg-footer-by d-flex text-white">
      <div
        class="
          container
          base-container-x
          py-2
          px-2
          d-none d-md-block d-xl-block d-lg-block
          align-self-center
        "
      >
        <div class="d-flex d-flex justify-content-between">
          <span class="small align-self-center">
            International Yoga
            Directory Worldwide Recognition | Copyright © World Yoga Alliance®
            1990 - {{ year }}
            <span class="ml-2 mr-2"> V{{ APP_VERSION }} </span>
          </span>
          <span class="d-block">
            <a :href="facebookUrl" target="_blank" class="text-white"
              ><span class="mr-3"
                ><font-awesome-icon :icon="['fab', 'facebook-f']" /></span
            ></a>
            <a :href="instagramUrl" target="_blank" class="text-white"
              ><span class="mr-3"
                ><font-awesome-icon :icon="['fab', 'instagram']" /></span
            ></a>
            <a :href="twitterUrl" target="_blank" class="text-white"
              ><span class="mr-3"
                ><font-awesome-icon :icon="['fab', 'twitter']" /></span
            ></a>
            <a :href="youtubeUrl" target="_blank" class="text-white"
              ><span><font-awesome-icon :icon="['fab', 'youtube']" /></span
            ></a>
            <div class="d-flex">
              <b-dropdown
                v-bind:text="currencySelected"
                variant="outline-light"
                class="m-2"
              >
                <b-dropdown-item href="#" @click="changeCurrency('usd')"
                  >USD</b-dropdown-item
                >
                <!-- <b-dropdown-item href="#" @click="changeCurrency('eur')">EUR</b-dropdown-item> -->
                <b-dropdown-item href="#" @click="changeCurrency('thb')"
                  >THB</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="changeCurrency('inr')"
                  >INR</b-dropdown-item
                >
              </b-dropdown>
              <b-dropdown
                v-bind:text="languageSelected"
                variant="outline-light"
                class="m-2"
              >
                <b-dropdown-item href="#" @click="changeLanguage('en')"
                  >English</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="changeLanguage('th')"
                  >ไทย</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="changeLanguage('zh')"
                  >中文</b-dropdown-item
                >
                <b-dropdown-item href="#" @click="changeLanguage('hi')"
                  >भारतीय भाषा</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </span>
        </div>
      </div>
      <div
        class="
          container
          base-container-x
          py-2
          px-2
          d-sm-block d-md-none d-lg-none d-xl-none
          justify-content-center
        "
      >
        <span class="d-flex justify-content-center text-copyright"
          >Copyright © World Yoga Alliance® 1990 - {{ year }} V{{
            APP_VERSION
          }}</span
        >
        <span class="d-flex mt-2 align-items-center justify-content-center">
          <a :href="twitterUrl" class="text-white d-block"
            ><span class="mr-4"
              ><font-awesome-icon :icon="['fab', 'facebook-f']" /></span
          ></a>
          <a :href="facebookUrl" class="text-white"
            ><span class="mr-4"
              ><font-awesome-icon :icon="['fab', 'instagram']" /></span
          ></a>
          <a :href="instagramUrl" class="text-white"
            ><span><font-awesome-icon :icon="['fab', 'twitter']" /></span
          ></a>
          <b-dropdown
            v-bind:text="currencySelected"
            variant="outline-light"
            class="ml-4"
          >
            <b-dropdown-item href="#" @click="changeCurrency('usd')"
              >USD</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changeCurrency('eur')"
              >EUR</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changeCurrency('thb')"
              >THB</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changeCurrency('inr')"
              >INR</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown
            v-bind:text="languageSelected"
            variant="outline-light"
            class="ml-4"
          >
            <b-dropdown-item href="#" @click="changeLanguage('en')"
              >English</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changeLanguage('th')"
              >ไทย</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changeLanguage('zh')"
              >中文</b-dropdown-item
            >
            <b-dropdown-item href="#" @click="changeLanguage('hi')"
              >भारतीय भाषा</b-dropdown-item
            >
          </b-dropdown>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
      instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
      youtubeUrl: process.env.VUE_APP_YOUTUBE_URL,
      languageSelected: '',
      currencySelected: '',
      year: new Date().getFullYear()
    }
  },
  mounted () {
    this.languageSelected = this.$store.getters.currentLanguage.toUpperCase()
    this.currencySelected = this.$store.getters.currentCurrency.toUpperCase()
    console.log(this.$store.getters.currentCurrency)
  },
  methods: {
    changeCurrency (currency) {
      this.setLocale(currency)
      this.$store.dispatch('changeCurrency', currency)
      this.currencySelected = currency.toUpperCase()
    },
    changeLanguage (lang) {
      this.setLocale(lang)
      this.$store.dispatch('changeLanguage', lang)
      this.languageSelected = lang.toUpperCase()
    }
  }
}
</script>

<style scoped>
#footer {
  /* Lock Text */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.bg-footer {
  background-color: #f5f7fa;
}
.bg-footer-by {
  background-color: #213159;
}
.text-copyright {
  font-size: 14px;
}
</style>
