<template>
  <div class="slide-container p-4 pt-n3 d-lg-none d-md-none d-xl-none d-md-block">
    <Slide  class="float-right" :isOpen="isOpen" @openMenu="handleOpenSlide" right>
      <div v-if="loggedIn" class="d-block px-0 font-weight-bold border-light border-bottom">
        <div class="d-flex align-items-center pl-3">
          <b-col cols="4" class="p-0">
            <b-avatar v-if="userProfile" size="5rem" variant="light" :src="userProfile.profile !== undefined ? userProfile.profile.avatar : PUBLIC_PATH+'img/profiles/user-circle-regular.svg'"></b-avatar>
          </b-col>
          <b-col cols="8" class="d-flex p-0" style="font-size:1.2rem;">
            <b-button
              block
              variant="outline-secondary"
              :class="visible ? 'text-primary-dark outline-secondary font-weight-bold border-0 ml-2' : 'collapsed text-primary-dark font-weight-bold border-0 ml-2'"
              :aria-expanded="visible ? 'true' : 'false'"
              aria-controls="collapse-profile-menu"
            >
            <div class="d-flex justify-content-between">
              <span class="text-left">{{ $t('Hello') }}, {{ (userInfo.firstName ? userInfo.firstName + ' ' + (userInfo.lastName || ''): userInfo.username) }}</span>
              <b-icon v-if="!visible" icon="chevron-down" class="text-right ml-2 d-none"></b-icon>
              <b-icon v-else icon="chevron-up" class="text-right ml-2 d-none"></b-icon>
              </div>
            </b-button>
          </b-col>
        </div>
        <b-collapse id="collapse-profile-menu" v-model="visible" class="mt-2">
          <b-card class="border-0 text-16" body-class="pb-0">
            <router-link :to="{name: 'userProfile'}" exact class="text-primary-dark mb-2 d-block">{{ $t('Profile') }}</router-link>
            <router-link :to="{name: 'changePassword'}" exact class="text-primary-dark mb-2 d-block">{{ $t('Change password') }}</router-link>
            <a href="#" class="text-primary-dark mb-2 justify-content-between d-none">{{ $t('Notifications') }} <b-badge pill variant="danger" class="float-right mt-1 px-3 d-none">1</b-badge></a>
            <router-link :to="{name: 'schoolProfile'}" class="text-primary-dark mb-2 d-block">{{ $t('School Registration') }}</router-link>
            <router-link :to="{name: 'teacherProfile'}" class="text-primary-dark mb-2 d-block">{{ $t('Teacher Registration') }}</router-link>
            <router-link :to="{name: 'billing'}" class="text-primary-dark mb-2 d-block">{{ $t('Billing') }}</router-link>
            <router-link :to="{name: 'authLogout'}" class="text-primary-dark mb-2 d-block">{{ $t('Log out') }}</router-link>
          </b-card>
        </b-collapse>
      </div>
      <router-link class="text-16" :to="{name: 'home'}" exact>{{ $t('Home') }}</router-link>
      <router-link class="text-16" :to="{name: 'searchDirectory'}" exact> {{ $t('Directory') }}</router-link>
      <a class="text-16" v-b-toggle.about>{{ $t('About') }}<span class="mr-3"><font-awesome-icon :icon="['fas', 'angle-down']"/></span></a>
      <b-collapse id="about" class="pt-0" accordion="slide-menu" role="tabpanel">
        <b-card class="border-0 text-16" body-class="pt-0 pb-0">
          <router-link :to="{name: 'ourHistory'}" exact class="text-primary-dark mb-2 d-block">{{ $t('Our History') }}</router-link>
          <router-link :to="{name: 'ourTeam'}" class="text-primary-dark mb-2 d-block">{{ $t('Meet Our Team') }}</router-link>
          <router-link :to="{name: 'membershipGuideline'}" class="text-primary-dark mb-2 d-block">{{ $t('Membership Guideline') }}</router-link>
          <router-link :to="{name: 'faq'}" class="text-primary-dark mb-2 d-block">{{ $t('FAQ') }}</router-link>
          <router-link :to="{name: 'contact'}" class="text-primary-dark mb-2 d-block">{{ $t('Contact') }}</router-link>
        </b-card>
      </b-collapse>
      <router-link :to="{name: 'standards'}" exact class="text-16">{{ $t('Standards') }}</router-link>
      <a class="text-16" v-b-toggle.member-benefits>{{ $t('Member Benefits') }}<span class="mr-3"><font-awesome-icon :icon="['fas', 'angle-down']"/></span></a>
      <b-collapse id="member-benefits" class="pt-0" accordion="slide-menu" role="tabpanel">
        <b-card class="border-0 text-16 block" body-class="pt-0 pb-0">
          <div><a class="text-16 text-primary-dark mb-2" @click="onChageHash('#schools')"  href="/member-benefit#schools">{{ $t('Benefits for Schools') }}</a></div>
          <div><a class="text-16 text-primary-dark mb-2" @click="onChageHash('#teachers')" href="/member-benefit#teachers">{{ $t('Benefits for Teachers') }}</a></div>
        </b-card>
      </b-collapse>
      <router-link class="text-16" :to="{name: 'feeDetails'}" exact> {{ $t('Fee') }}</router-link>
      <a class="text-16" v-b-toggle.community>{{ $t('Community') }}<span class="mr-3"><font-awesome-icon :icon="['fas', 'angle-down']"/></span></a>
      <b-collapse id="community" class="pt-0" accordion="slide-menu" role="tabpanel">
        <b-card class="border-0 text-16" body-class="pt-0">
          <router-link :to="{name: 'videos'}" exact class="text-primary-dark mb-2 d-block">{{ $t('Videos') }}</router-link>
          <router-link :to="{name: 'exclusiveCenter'}" exact class="text-primary-dark mb-2 d-block">{{ $t('Exclusive Center') }}</router-link>
          <router-link :to="{name: 'wyaEvents'}" exact class="text-primary-dark mb-2 d-block">{{ $t('WYA Events') }}</router-link>
          <router-link :to="{name: 'memberPark'}" exact class="text-primary-dark mb-2 d-block">{{ $t('Yoga Stores') }}</router-link>
        </b-card>
      </b-collapse>
      <div class="row flex-justify-content-space-around mt-3" v-if="!loggedIn">
        <b-col sm="6" class="mb-sm-0 mb-3">
          <b-button pill variant="primary" :to="{ name: 'authenLogin' }" class="text-light btn-block btn-auth-login font-weight-bold">{{ $t('Login') }}</b-button>
        </b-col>
        <b-col sm="6" class="mb-sm-0 mb-3">
          <b-button pill :to="{ name: 'authenSignup' }" class="btn-block btn-auth-sign-up text-light pl-0 pr-0 font-weight-bold">{{ $t('Sign up') }}</b-button>
        </b-col>
      </div>
      <div class="row d-none" v-if="!loggedIn">
        <div class="col-12 text-center">
          <h5 class="font-weight-bold text-primary-dark">{{ $t('Login with') }}</h5>
          <span class="d-block">
            <a href="#" role="button" aria-pressed="true" class="btn btn-lg rounded-circle bg-facebook"><font-awesome-icon :icon="['fab', 'facebook-f']" class="text-white"/></a>
            <a href="#" role="button" aria-pressed="true" class="btn btn-lg rounded-circle bg-white"><b-img alt="login with google" width="43" :src="require('../../assets/images/icon/google.png')"></b-img></a>
          </span>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="row d-relative">
        <div class="block-footer-nav">
          <span class="text-gray-light d-block text-center">
            <small class="text-primary-dark text-footer-nav ml-2">Copyright © World Yoga Alliance® 1990 - {{ currentYear }}</small><br>
            <div class="text-center">
              <small class="text-primary-dark text-footer-nav mr-2">V{{ APP_VERSION }} </small>
              <a :href="twitterUrl" :class="iconHeader"><small class="mr-3 text-center text-primary-dark"><font-awesome-icon :icon="['fab', 'twitter']"/></small></a>
              <a :href="facebookUrl" :class="iconHeader"><small class="mr-3 text-center text-primary-dark"><font-awesome-icon :icon="['fab', 'facebook-f']"/></small></a>
              <a :href="instagramUrl" :class="iconHeader"><small class="text-primary-dark text-center"><font-awesome-icon :icon="['fab', 'instagram']"/></small></a>
            </div>
          </span>
        </div>
      </div>
    </Slide>
  </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'

export default {
  name: 'NavbarSlide',
  components: {
    Slide
  },
  data () {
    return {
      twitterUrl: process.env.VUE_APP_TWITTER_URL,
      facebookUrl: process.env.VUE_APP_FACEBOOK_URL,
      instagramUrl: process.env.VUE_APP_INSTAGRAM_URL,
      iconHeader: typeof this.$route.meta.bgClass !== 'undefined' ? 'text-white' : 'header-text-icon',
      currentYear: new Date().getFullYear(),
      visible: true,
      isOpen: false
    }
  },
  watch: {
    $route (to, _) {
      this.isOpen = false
    }
  },
  computed: {
    userProfile () {
      return this.$store.getters.userProfile
    },
    userInfo () {
      return this.$store.getters.userInfo
    },
    loggedIn () {
      return this.$store.getters.loggedIn
    }
  },
  mounted () {
    if (this.$store.getters.loggedIn) {
      this.$store.dispatch('userInfo')
      this.$store.dispatch('userProfile')
    }
  },
  methods: {
    handleOpenSlide () {
      this.isOpen = true
    },
    handleCloseSlide () {
      this.isOpen = false
    },
    onChageHash (hash) {
      this.handleCloseSlide()
      if (this.$route.name === 'memberBenefit') {
        const EventBus = this.$root
        EventBus.$emit('on-change-hash', hash)
      }
    }
  }
}
</script>
<style scope>
</style>
