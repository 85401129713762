<template>
    <b-dropdown id="notification-d-menu" class="py-0" :no-caret="true" right toggle-class="bg-transparent border-0 py-0">
        <template slot="button-content">
        <div class="notification-icon-wrapper">
            <b-icon icon="bell" class="text-primary-dark" style="margin: auto"></b-icon>
            <b-badge v-if="unreadNotifications && unreadNotifications > 0" pill variant="danger" class="notification-badge">{{ unreadNotifications }}</b-badge>
        </div>
        </template>
        <template v-if="notifications.length">
        <b-dropdown-item 
            v-for="notification in notifications" 
            :key="notification.id" 
            :class="{'notification-item': true, 'unread': !notification.read}"
            @click="handleNotificationClick(notification)"
        >
            <div class="notification-message" v-html="parseMarkdown(notification.message)"></div>
            <small>{{ formatDate(notification.notify_at) }}</small>
        </b-dropdown-item>
        </template>
        <template v-else>
        <b-dropdown-item class="text-center text-muted">No new notifications</b-dropdown-item>
        </template>
   </b-dropdown>
</template>

<script>
import Api from '../../api/api'
import moment from 'moment'

export default {
  name: 'NotificationButton',
  data() {
    return {
      notifications: [],
      unreadNotifications: 0
    };
  },
  mounted() {
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
        try {
            const res = await Api.notifications();
            const data = res.data;
            this.notifications = data.results;
            this.unreadNotifications = data.unread_count;
        } catch (error) {
            console.error(error);
        }
    },
    formatDate(date) {
        return moment(date).fromNow();
    },
    async markAsRead(notification) {
        try {
            notification.read = true;
            this.unreadNotifications -= 1;
            await Api.readSingleNotification(notification.id);
        } catch (error) {
            console.error(error);
        }
    },
    handleNotificationClick(notification) {
        if (notification.custom_url) {
            window.open(notification.custom_url, '_blank');
        }
        notification.read = true;
        this.markAsRead(notification);
    },
    parseMarkdown(message) {
        message = message.replace(/(\*\*|__)(.*?)\1/g, '<b>$2</b>');
        return message;
    }
  },
};
</script>

<style scoped>
.notification-item {
    width: 300px; /* Fixed width for desktop */
}

.notification-message {
    white-space: wrap;
}

.unread {
    background-color: #fff;
}

@media (max-width: 768px) {
    .notification-item {
        width: 100%;
    }
}
</style>